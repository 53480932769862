export interface VIDEO_ITEM {
    id: string;
    userId: string;
    videoId: string;
    videoUrl: string;
    status: string | number;
    videoInfo: string;
    level: string;
    publishType: string;
    createTime: string;
    updateTime: string;
    publishTime: string;
    videoLength: string;
    sendId: string;
    imageUrl: string;
    videoStatus: string;
    bucket: string;
    ext: string;
    token: string;
    videoTitle: string;
    uploadId: string;
    coverTokTen: string;
    publishFailReason: string;
    transId: string;
    fileName: string;
    popoverVisible: boolean;
}
export const homepageRouter = '/cutmotionMicro/reactTest/publish';

<template>
    <div class="logo_wrapper" @click="handleClickLogin">
        <img :src="logo" alt="" />
        <span class="name">Cut Motions</span>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import logo from '@/assets/imgs/logo.png';
import { useSSOStore } from '@/stores';
import { homepageRouter } from '@/lib/const';

const sso = useSSOStore();
const router = useRouter();

const props = defineProps({
    black: {
        type: Boolean,
        default: false,
    },
});

function handleClickLogin() {
    if (sso.logIn) {
        router.push(homepageRouter);
    } else {
        router.push('/login');
    }
}
</script>

<style scoped lang="scss">
.logo_wrapper {
    cursor: pointer;
    height: 35px;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 16px;
    flex-shrink: 0;
    img {
        object-fit: cover;
        width: 28px;
        height: 36px;
        @include rwd-m() {
            scale: 0.7;
        }
    }
    .name {
        margin-left: 8px;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #333333;
        @include rwd-m() {
            font-size: 16px;
        }
    }
}
</style>

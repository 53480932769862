<template>
    <div class="header_wrapper">
        <div class="header_left">
            <ks-popover v-if="showMobileMenu" v-model:visible="state.show" placement="top-start" :width="200" trigger="click">
                <template #reference>
                    <IconEditorCatalogue class="mr-16" />
                </template>
                <Menu @click="state.show = false" />
            </ks-popover>
            <Logo />
        </div>
        <div class="header_right">
            <ks-radio-group v-if="globalStore.versionBtnShow" v-model="globalStore.platformVersion" size="mini" plain @change="changeVersion">
                <ks-radio-button label="old"><k18n>旧版</k18n></ks-radio-button>
                <ks-radio-button label="new"><k18n>新版</k18n></ks-radio-button>
            </ks-radio-group>
            <LangSelect class="mr-16 ml-4" />
            <ks-dropdown v-if="sso.logIn">
                <span class="user_name">{{ globalStore.userInfo?.userName || globalStore.userInfo?.userId || '--' }} </span>
                <template #dropdown>
                    <ks-dropdown-menu>
                        <ks-dropdown-item @click="handleClickLogout">
                            <k18n>退出登录</k18n>
                        </ks-dropdown-item>
                    </ks-dropdown-menu>
                </template>
            </ks-dropdown>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';
import { IconEditorCatalogue } from '@kibt/pro-icons';
import { useRouter } from 'vue-router';
import LangSelect from './LangSelect.vue';
import { useGlobalStore, useSSOStore } from '@/stores';
import Logo from './Logo.vue';
import Menu from '../menu/Menu.vue';
import { PlatformVersionEnum } from '@/stores/global';

const globalStore = useGlobalStore();
const sso = useSSOStore();
const router = useRouter();
const state = reactive({
    searchValue: '',
    show: false,
});

async function handleClickLogout() {
    await sso.logout();
    router.push('/login');
}
const showMobileMenu = computed(() => {
    const pathname = window.location.pathname;
    const showPath = !['/login', '/signup', '/password'].includes(pathname);
    return showPath && globalStore.isMobileMode;
});
function changeVersion(val) {
    if (val === PlatformVersionEnum.OLD) {
        router.push('/pool');
    } else {
        router.push('/cutmotionMicro/reactTest/publishManage');
    }
}
</script>

<style scoped lang="scss">
.header_wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: $header_height;
    background: #ffffff;
    .header_left {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 16px;
        svg {
            flex-shrink: 0;
        }
    }
    .header_right {
        display: flex;
        align-items: center;
        .user_name {
            max-width: 150px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
        }
    }
}
.item {
    display: flex;
    align-items: center;
    :deep(.tooltip) {
        line-height: 1;
    }
}
</style>
